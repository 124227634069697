<template>
  <ion-page>
    <ion-content :fullscreen="true">
      <template v-if="!loading && job">
        <div class="ios hydrated container-page-header">
          <div class="page-title">
            <h1>Complete & Send</h1>
          </div>
          <div class="job-title">{{ title }}</div>
          <div class="job-code">#{{ job.job_code }}</div>
        </div>

        <div class="notes-field">
          <div class="title">Your Notes:</div>
          <p class="text">{{ notes }}</p>
        </div>
      </template>
    </ion-content>

    <ion-footer>
      <ion-toolbar>
        <ion-tabs>
          <ion-router-outlet />
          <ion-tab-bar slot="bottom" color="primary">
            <ion-tab-button tab="cancel" color="primary" @click="cancel">
              <uil-times size="25px" />
              <ion-label>Cancel</ion-label>
            </ion-tab-button>

            <ion-tab-button tab="sendnow" @click="sendNow">
              <uil-check size="25px" />
              <ion-label>Send Now</ion-label>
            </ion-tab-button>
          </ion-tab-bar>
        </ion-tabs>
      </ion-toolbar>
    </ion-footer>
  </ion-page>
</template>

<script>
import { IonContent, IonPage, IonTabBar, IonTabButton, IonTabs, alertController,
  toastController, IonLabel, IonToolbar, IonFooter, IonRouterOutlet } from '@ionic/vue';
import { mapGetters, mapState } from 'vuex';
import { UilTimes, UilCheck } from '@iconscout/vue-unicons';
import mutatesJobsMixin from "@/mixins/mutates-jobs";
import loadsData from "@/mixins/loads-data";

export default {
  mixins: [
    loadsData,
    mutatesJobsMixin,
  ],
  components: {
    IonContent, IonPage, IonTabBar, IonTabButton, IonTabs, UilTimes, UilCheck,
    IonLabel, IonToolbar, IonFooter, IonRouterOutlet
  },
  data () {
    return {
      loading: false
    }
  },
  computed: {
    ...mapState({
      network: state => state.auth.network,
    }),
    ...mapGetters({
      user: 'auth/user',
    }),
    jobId () {
      return this.$route.params.id;
    },
    title () {
      let title = "";
      if (this.asset) {
        title += this.asset.address;
        if (this.asset.address2 != null) {
          title += ", " + this.asset.address2;
        }
        if (this.asset.address3 != null) {
          title += ", " + this.asset.address3;
        }
        if (this.asset.city != null) {
          title += ", " + this.asset.city;
        }
        if (this.asset.postcode != null) {
          title += ", " + this.asset.postcode;
        }
      }
      return title;
    },
    notes () {
      return (this.asset && this.asset.notes)
        ? this.asset.notes
        : "There are no surveyor notes for this job."
    },
  },
  methods: {
    cancel () {
      this.$router.back()
    },
    sendNow () {
      console.log("Submit.methods.sendNow()", this.network);
      if (this.network.connectionType === "none") {
        // we are now offline
        this.offlineAlert();
      } else if (this.network.connectionType === "cellular") {
        // warn the user that it will use data and they should have good service
        // recommend wifi
        this.presentAlertConfirm({ id: this.job.id });
      } else {
        this.pushJob({ id: this.job.id });
      }
    },
    async presentAlertConfirm (options) {
      const alert = await alertController
        .create({
          cssClass: 'cellular-alert',
          header: 'Mobile Data Alert!',
          message: 'You are currently using mobile data. When saving a survey we recommend using WiFi or a strong cellular connection. Please note that continuing will also contribute to your data usage.',
          buttons: [
            {
              text: 'Cancel',
              role: 'cancel',
              cssClass: 'secondary',
            },{
              text: 'Continue',
              handler: () => {
                this.pushJob(options);
              },
            },
          ],
        });
      return alert.present();
    },
    async pushJob (options) {
      await this.loadingTriggered(undefined, [
        "Submitting jobs can take a while.",
        "Do not close the app.",
        'Please wait…',
      ]);
      await this.$store.dispatch("brightchecker/sendJob", options)
        .then(
          () => {
            this.closeModal();
            this.saveSuccess();
          },
          (axiosException) => {
            this.closeModal();
            this.saveFail(
              // For some reason we get "right side of assignment cannot be destructured" if we destructure axiosException as argument
              (axiosException && axiosException.response && axiosException.response.data && axiosException.response.data.message)
                ? axiosException.response.data.message
                : null
            );
          }
        )
    },
    async offlineAlert () {
      const alert = await alertController
        .create({
          cssClass: 'offline-alert',
          header: 'You are offline!',
          message: 'To save a survey, please connect to a WiFi (recommended) or cellular network.',
          buttons: ['OK'],
        });
      return alert.present();
    },
    async saveSuccess () {
      const alert = await alertController
        .create({
          cssClass: 'save-alert',
          header: 'Job Saved',
          message: 'The job has been successfully saved.',
          buttons: ['OK'],
        });

      await alert.present();

      alert.onDidDismiss().then(() => {
        this.$router.push({ name: 'home' })
      });
    },
    async saveFail (errorMessage) {
      console.error("saveFail", errorMessage)
      const toast = await toastController
        .create({
          message: errorMessage ? errorMessage : 'The job failed to save.', // For some reason null coalesce was throwing errors here
          cssClass: "failed-job",
          buttons: [
            {
              text: 'Dismiss',
              role: 'cancel',
            },
          ]
        });

      await toast.present();
    },
  },
}
</script>

<style scoped lang="scss">
h1, h2 {
  text-align: center;
  margin-top: 0;
  font-size: 20px;
  font-weight: 800;
  line-height: 30px;
  color: #373636;
}

ion-toolbar {
  --min-height: 50px;
}

ion-tab-button {
  svg {
    color: #DB9123;
  }
  --background: #172943;
  color: #ffffff;
}

.page-title {
  margin-bottom: 30px;
}

.job-title, .job-code {
  font-size: 16px;
  font-weight: 800;
  line-height: 19px;
  color: #373636;
  font-family: 'Lato', sans-serif;
  text-align: center;
  margin-bottom: 10px;
  text-transform: uppercase;
}

.container-page-header {
  padding: 0 20px;
}

.notes-field {
  background-color: #ffffff;
  padding: 20px;
  margin: 40px 30px 0 30px;
  box-shadow: rgb(0 0 0 / 12%) 0 4px 16px;
  .title {
    font-size: 14px;
    font-weight: 800;
    margin-bottom: 5px;
  }
  .text {
    font-size: 14px;
    font-weight: 400;
    margin: 0;
  }
}
</style>
